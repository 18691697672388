<template>
  <div>
    <v-app-bar app color="light" dark class="d-flex justify-center" elevation="1" v-if="!isloading">
      <div class="d-flex align-center">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/images/visicardia.png"
          transition="scale-transition" width="40" />
        <span class="font-light">Visicardia</span>
      </div>
    </v-app-bar>

    <v-main v-if="!isloading">
      <v-card class="mx-auto mt-3" max-width="344">
        <template v-if="!showForgotPasswordDialog">
          <v-card-text> Welcome back! </v-card-text>
          <p class="display-1 px-3">Login to continue</p>
          <!-- <v-card-actions class="px-3">
            <v-btn color="red accent-2" dark @click="login">
              Login With Google
            </v-btn>
          </v-card-actions>
          <h4 class="px-10">- OR -</h4> -->
          <v-text-field class="px-5" v-model="email" label="Email" style="box-align: center" autofocus></v-text-field>
          <v-text-field class="px-5" v-model="password" label="Password" style="box-align: center"
            :append-icon="hidePassword ? 'fa-eye' : 'fa-eye-slash'" @click:append="() => (hidePassword = !hidePassword)"
            :type="hidePassword ? 'password' : 'text'" @keydown.enter="sendOtp"></v-text-field>
          <v-row class="mr-3" style="justify-content: flex-end">
            <v-btn text x-small @click="showForgotPasswordDialog = true">forgot password?</v-btn>
          </v-row>
          <v-card-actions class="pa-5" style="justify-content: center">
            <v-btn class="px-10" color="red accent-2" dark @click="signIn">
              Sign In
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-title> Forgot Password </v-card-title>
          <v-card-subtitle>Enter your email to send verification link</v-card-subtitle>
          <v-text-field class="px-5" v-model="email" label="Email" style="box-align: center" autofocus></v-text-field>
          <v-row class="ma-5">
            <v-btn v-if="!emailSent" color="red accent-2" small dark :disabled="!email" @click="sendEmailVerification">
              Send Email Verification
            </v-btn>
            <div v-else>Check Email Inbox</div>
            <v-spacer></v-spacer>
            <v-btn small @click="showForgotPasswordDialog = false">cancel</v-btn>
          </v-row>
        </template>
        <v-skeleton-loader />
      </v-card>
    </v-main>
    <v-container v-if="isloading" class="text-center pt-3">
      <v-icon>fas fa-circle-notch fa-spin</v-icon>
      <p>Authenticating...</p>
    </v-container>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Auth',
  data: () => ({
    isloading: false,
    email: '',
    password: '',
    hidePassword: true,
    showForgotPasswordDialog: false,
    emailSent: false,
  }),
  computed: {
    ...mapState({
      isRole: (state) => state.isRole,
      currentUser: (state) => state.currentUser,
      admin: (state) => state.admin,
      hradmin: (state) => state.hradmin,
      superadmin: (state) => state.superadmin,
    }),
  },
  created() {
    const currentRoute = this.$router.currentRoute;
    if (!this.currentUser) {
      this.isloading = true;

      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const userTableData = await this.fetchCurrentUser(user.uid);
          // console.log('userTableData ', userTableData);
          // const redUrl = this.$route.query?.red || "/admin";
          if (userTableData) {
            if (
              userTableData?.roleId == this.admin ||
              userTableData?.roleId == this.hradmin ||
              userTableData?.roleId == this.superadmin
            ) {
              return;
              // if (currentRoute.path !== '/') {
              //   console.log('routew', currentRoute);
              //   this.$router.replace('/');
              // }

            } else {
              await firebase.auth().signOut();
              alert('this user is not admin or superadmin or hradmin');
              return;
            }
          }
        }
        this.isloading = false;
      });
    }
  },
  methods: {
    ...mapActions(['fetchCurrentUser']),
    async login() {
      const provider = new firebase.auth.GoogleAuthProvider();
      const res = await firebase.auth().signInWithPopup(provider);
      if (res.user) {
        this.$router.replace('/');
        // this.$router.replace("/admin");
      }
    },
    async signIn() {
      try {
        const res = await new firebase.auth().signInWithEmailAndPassword(
          this.email,
          this.password
        );
        this.$router.replace('/');
      } catch (e) {
        console.log(`FirebaseException: ${e}`);
        alert(e);
      }
    },
    async sendEmailVerification() {
      try {
        await firebase.auth().sendPasswordResetEmail(this.email);
        this.emailSent = true;
        setTimeout(() => {
          this.emailSent = false;
          this.showForgotPasswordDialog = false;
        }, 6000);
      } catch (e) {
        this.emailSent = false;
        alert(`${e}`);
      }
    },
  },
};
</script>
